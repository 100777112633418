@charset "UTF-8";

@font-face {
  font-family: "exclusive-icon";
  src: url("../fonts/exclusive-icon.eot");
  src: url("../fonts/exclusive-icon.eot?#iefix") format("embedded-opentype"), url("../fonts/exclusive-icon.woff") format("woff"), url("../fonts/exclusive-icon.ttf") format("truetype"), url("../fonts/exclusive-icon.svg#exclusive-icon") format("svg");
  font-weight: normal;
  font-style: normal;
}


.ei {
  display: inline-block;
  font: normal normal normal 14px/1 exclusive-icon;
  font-size: inherit;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[data-icon]:before {
  font-family: "exclusive-icon" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="ei-"]:before, [class*=" ei-"]:before {
  font-family: "exclusive-icon" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ei-unchecked-boxed:before {
    content: "\e19e";
}

.ei-checked-box:before {
    content: "\e190";
}

.ei-minus-boxed:before {
    content: "\e19a";
}

.ei-pause-1:before {
    content: "\e121";
}