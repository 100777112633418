.invoice, .invoice-outside {
	max-width: 810px;
    margin: auto;
    margin-bottom: 15px;
}
.invoice {
	margin-top: 15px;
	.btn {
		min-width: 180px;
	}
}
.invoice-top {
	h5 {
		margin-bottom: 0;
		line-height: 50px;
		float: right;
		font-size: 13px;
		b {
			margin-left: 6px;
    		font-weight: 300;
		}
	}
	.img-responsive {
		height: 27px;
		margin-top: 7px;
		float: left;
		margin-right: 5px;
	}
}
.invoice-bottom {
	min-height: 61px;
}
.timer-text {
	line-height: 24px;
}
.status-container {
	.status-label, .status-el {
		float: left;
		line-height: 24px;
	}
	.status-label {
		margin-right: 10px;
	}
	.status-name {
		position: relative;
		top: 1px;
	}
}
.qr-code {
	margin-top: 30px;
    margin-bottom: 20px;
    canvas {
    	 border: 1px solid $border-color;
    	 padding: 15px;
    }
}
.invoice-buttons {
	 img {
		height: 45px;
		margin-right: 8px;
	}
	span, .text-dark {
		position: relative;
		top: 2px;
	}
}
.invoice-icon {
    font-size: 180px;
    margin-top: 60px;
    margin-bottom: 80px;
}
.aside-text {
	b {
		color: $default-dark;	
	}
	font-size: 90%;
    position: absolute;
    margin-top: 10px;
    right: 25px;
}
.fancy {
		margin-bottom: 5px;
}
.qr-mobile {
	display: none;
}
@media (max-width: 991px) {
	.invoice-top {
		.img-responsive {
			float: left;
	    }
    	h5 {
    		display: none;
    	}
	}
}
@media (max-width: 767px) {
	.container {
		max-width: 100%;
		padding-left: 0;
    	padding-right: 0;
	}
	.invoice-bottom {
		.status-time {
			text-align: left!important;
			margin-top: 5px;
			b {
				font-size: 16px;
				margin-left: 5px;
			}
		}
	}
	.card {
		.card-body {
			.text-main {
				text-align: left!important;
			}
			.qr-code {
					text-align: center!important;
			}
		}
	}
	.invoice .invoice-values {
		font-size: 16px;
	}
	.transaction {
		text-align: left!important;
    	margin-top: 5px;
	}
	.copy-wrapper .copy-text {
		top: -2px;
	}
	.qr-code{
		display: none;
	}
	.qr-mobile {
		display: block;
	}
}
@media (max-width: 522px) {
	.pdd-horizon-30 {
		padding-left: 10px !important;
    	padding-right: 10px !important;
	}
	.btn {
		width: 100%;
		&.copy-text {
			width: auto;
			top: -25px;
    		left: 65px;
		}
	}
}
@media (max-width: 482px) { 
	body {
		background-color: $default-white;
		font-size: 13px;
	}
	.container-fluid {
		padding-left: 0;
		padding-right: 0;
	}
	.invoice {
	    margin-top: 0;
	    .mrg-horizon-25 {
	    	margin-left: 10px!important;
	    	margin-right: 10px!important;
	    }
	    .pdd-horizon-25 {
	    	padding-left: 15px!important;
	    	padding-right: 15px!important;
	    }
	    .col-md-12 {
	    	padding-left: 10px;
	    	padding-right: 10px;
	    }
	    .invoice-buttons {
	    	.col-md-12 {
	    		padding-left: 15px;
	    		padding-right: 15px;
	    	}
	    }
	    .invoice-values {
			font-size: 12px;
		}
	}
	.card {
		.card-body {
			padding: 15px;
			padding-bottom: 0; 
			.pdd-horizon-30 {
				padding-left: 5px!important;
				padding-right: 5px!important;
			}
			.mrg-top-30 {
				margin-top: 15px!important;
			}
			.mrg-btm-15 {
				margin-bottom: 0!important;
			}
			.qr-code {
					text-align: left!important;
			}
		}
	}
	.qr-code canvas {
		width: 100%!important;
    	height: auto!important;
	}
	.invoice-outside {
		padding-right: 10px;
		padding-left: 10px;
		.col-md-12 {
			padding-right: 5px;
			padding-left: 5px;
		}
	}
	.invoice-bottom .status-time b {
		font-size: 14px;
	}
	.font-size-16 {
		font-size: 14px!important;
	}
}